// styles
import "./Parallax2.css";

export default function Parallax2() {
  return (
    <div className="parallax2">
      <div className="parallax2__wrap"></div>
    </div>
  );
}
