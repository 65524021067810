// styles
import "./BtnSubmit.css";

export default function BtnSubmit() {
  return (
    <button type="submit" className="btnSubmit">
      Отправить
    </button>
  );
}
