// styles
import "./Parallax3.css";

export default function Parallax3() {
  return (
    <div className="parallax3">
      <div className="parallax3__wrap"></div>
    </div>
  );
}
